import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
	faArrowDown,
	faCalendarDays,
	faBars,
	faTimes,
	faStars,
	faConciergeBell,
	faRulerCombined,
	faUsers,
	faBlanket,
	faLocationDot,
	faCheck,
	faPhone,
	faEnvelope,
} from '@fortawesome/pro-light-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import {
	faFacebook,
	faInstagram,
	faLinkedin,
	faPinterest,
	faSoundcloud,
	faSpotify,
	faTwitter,
	faWhatsapp,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
	faStar,
	faHeart,
	faWineGlass,
	faBicycle,
	faLeaf,
	faStarHalf,
	faTrees,
	faWater,
	faBedFront,
	faHouseChimneyWindow,
	faBath,
	faFireplace,
} from '@fortawesome/pro-solid-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
	faArrowDown,
	faChevronDown,
	faChevronUp,
	faCalendarDays,
	faBars,
	faCheck,
	faTrees,
	faWater,
	faStars,
	faConciergeBell,
	faBlanket,
	faTimes,
	faFacebook,
	faInstagram,
	faLinkedin,
	faPhone,
	faEnvelope,
	faPinterest,
	faSoundcloud,
	faSpotify,
	faTwitter,
	faWhatsapp,
	faYoutube,
	faLocationDot,
	faBedFront,
	faHouseChimneyWindow,
	faBath,
	faFireplace,
	faStar,
	faStarHalf,
	faRulerCombined,
	faHeart,
	faWineGlass,
	faBicycle,
	faLeaf,
	faUsers,
);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
