import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as bikeroutesa2pQWgwmvHMeta } from "/vercel/path0/pages/bikeroutes.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as _91item_93GHVunr73gyMeta } from "/vercel/path0/pages/events/[item].vue?macro=true";
import { default as indexuedj0fLb5OMeta } from "/vercel/path0/pages/events/index.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as _91item_93AtcCLGC5rsMeta } from "/vercel/path0/pages/highlights/[item].vue?macro=true";
import { default as indexU9GiN3ozqWMeta } from "/vercel/path0/pages/highlights/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91item_93sEfMDhemu6Meta } from "/vercel/path0/pages/landingpages/[item].vue?macro=true";
import { default as index2JCIGngW9GMeta } from "/vercel/path0/pages/landingpages/index.vue?macro=true";
import { default as pressJq1FmNOBviMeta } from "/vercel/path0/pages/press.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as restaurantJB34tLIxmjMeta } from "/vercel/path0/pages/restaurant.vue?macro=true";
import { default as roomstL5659IyVgMeta } from "/vercel/path0/pages/rooms.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
import { default as _91item_93zKgFSelgFtMeta } from "/vercel/path0/pages/vacancies/[item].vue?macro=true";
import { default as indexp3qWQtgtPJMeta } from "/vercel/path0/pages/vacancies/index.vue?macro=true";
export default [
  {
    name: "page___en",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___nl",
    path: "/nl/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "bikeroutes___en",
    path: "/bike-routes",
    component: () => import("/vercel/path0/pages/bikeroutes.vue").then(m => m.default || m)
  },
  {
    name: "bikeroutes___nl",
    path: "/nl/fietsroutes",
    component: () => import("/vercel/path0/pages/bikeroutes.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/contact-us",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___nl",
    path: "/nl/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "events-item___en",
    path: "/meet-and-celebrate/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue").then(m => m.default || m)
  },
  {
    name: "events-item___nl",
    path: "/nl/events/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue").then(m => m.default || m)
  },
  {
    name: "events___en",
    path: "/meet-and-celebrate",
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___nl",
    path: "/nl/events",
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___en",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___nl",
    path: "/nl/faq",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "gallery___en",
    path: "/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___nl",
    path: "/nl/galerij",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "highlights-item___en",
    path: "/do-and-explore/:item()",
    component: () => import("/vercel/path0/pages/highlights/[item].vue").then(m => m.default || m)
  },
  {
    name: "highlights-item___nl",
    path: "/nl/bezienswaardigheden/:item()",
    component: () => import("/vercel/path0/pages/highlights/[item].vue").then(m => m.default || m)
  },
  {
    name: "highlights___en",
    path: "/do-and-explore",
    component: () => import("/vercel/path0/pages/highlights/index.vue").then(m => m.default || m)
  },
  {
    name: "highlights___nl",
    path: "/nl/bezienswaardigheden",
    component: () => import("/vercel/path0/pages/highlights/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "landingpages-item___en",
    path: "/landingpages/:item()",
    component: () => import("/vercel/path0/pages/landingpages/[item].vue").then(m => m.default || m)
  },
  {
    name: "landingpages-item___nl",
    path: "/nl/landingpages/:item()",
    component: () => import("/vercel/path0/pages/landingpages/[item].vue").then(m => m.default || m)
  },
  {
    name: "landingpages___en",
    path: "/landingpages",
    component: () => import("/vercel/path0/pages/landingpages/index.vue").then(m => m.default || m)
  },
  {
    name: "landingpages___nl",
    path: "/nl/landingpages",
    component: () => import("/vercel/path0/pages/landingpages/index.vue").then(m => m.default || m)
  },
  {
    name: "press___en",
    path: "/press",
    component: () => import("/vercel/path0/pages/press.vue").then(m => m.default || m)
  },
  {
    name: "press___nl",
    path: "/nl/pers",
    component: () => import("/vercel/path0/pages/press.vue").then(m => m.default || m)
  },
  {
    name: "privacy___en",
    path: "/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___nl",
    path: "/nl/privacy",
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "restaurant___en",
    path: "/eat-and-drink",
    component: () => import("/vercel/path0/pages/restaurant.vue").then(m => m.default || m)
  },
  {
    name: "restaurant___nl",
    path: "/nl/restaurant",
    component: () => import("/vercel/path0/pages/restaurant.vue").then(m => m.default || m)
  },
  {
    name: "rooms___en",
    path: "/stay-and-sleep",
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: "rooms___nl",
    path: "/nl/stay-sleep",
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___en",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___nl",
    path: "/nl/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "vacancies-item___en",
    path: "/work-with-us/:item()",
    component: () => import("/vercel/path0/pages/vacancies/[item].vue").then(m => m.default || m)
  },
  {
    name: "vacancies-item___nl",
    path: "/nl/vacatures/:item()",
    component: () => import("/vercel/path0/pages/vacancies/[item].vue").then(m => m.default || m)
  },
  {
    name: "vacancies___en",
    path: "/work-with-us",
    component: () => import("/vercel/path0/pages/vacancies/index.vue").then(m => m.default || m)
  },
  {
    name: "vacancies___nl",
    path: "/nl/vacatures",
    component: () => import("/vercel/path0/pages/vacancies/index.vue").then(m => m.default || m)
  }
]